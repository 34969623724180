// Navigation
.admin-bar .navigation{
	top: 20px;
}

.home .navigation{
	background: none;

	.site-title,
	a{
		color: #fff;
	}
	svg{
		fill: rgba(255,255,255,1);

	}
}



.site-search{
	position: relative;
	margin: 0 50px;
	box-sizing: initial;
	display: inline-block;
	min-width: 17px;
	cursor: pointer;

	input{
		border: none;
		padding: 7px 0 7px 0;
		display: block;
		width: 0;
		box-sizing: initial;
		transition: .2s;
		opacity: 0;
		outline: none;
	}

	svg{
		position: absolute;
		top: 1px;
		width: 18px;
	}

	&.is-active{

		input{
			padding: 7px 20px 7px 40px;
			box-shadow: 0 0 10px rgba(0,0,0,.4);
			width: 150px;
			opacity: 1;
		}

		svg{
			position: absolute;
			left: 15px;
			fill: rgba(0,0,0,1);
		}
	}

	@include below($s){
		margin: 0 20px;
	}

}


.initial{
	.navigation{
		position: absolute;
		opacity: 1;
		transform: translateY(0%);
	}
}

.is-active{
	.navigation{
		transition: .2s ease-out;
		opacity: 1;
		transform: translateY(0%);
	}
}

.navigation{
	will-change: transform;
	z-index: 10;
	background: #fff;
	padding: 40px;
	position: fixed;
	top: 0;
	width: 100%;
	opacity: 0;
	transition: .2s ease-out;
	transform: translateY(-100%);
	// min-height: 100vh;

	&.initial{
		position: absolute;
	}


	@include below($m){
		padding: 15px
	}

	svg {
		display: inline-block;
		height: 100%;
	}

	.site-logo{
		display: inline-block;
		height: 25px;

		@include below($m){
			position: relative;
			width: 90px;
			height: 14px;
			top: 8px;
		}

		@include below($s){
			top: 2px;
		}
	}

	.site-title{
		font-size: 30px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		@include below($m){
			font-size: 17px;
		}

		@include below($s){
			font-size: 15px;
		}

	}

	.site-translate-button{
		text-decoration: none;
		z-index: 10;

		&.show-s {
		    position: absolute;
		    top: 10px;
		    left: 30px;
		    border: none;
		    padding: 4px 0px;
		    font-size: 17px;
		}
	}

	.site-menu-button{
		text-decoration: none;
		cursor: pointer;
	}
	.icon-menu {
		display: inline-block;
		margin-left: 20px;
		position: relative;
		top: 3px;
		width: 22px;

		@include below($m){
			width: 25px;
		}
	}
}

.sticky-navigation {
	position: absolute;
}

.menu-main-menu-container {
    position: fixed;
	padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 10000;
    background-image: linear-gradient(200deg ,#3B8F98,#FBE300);
    opacity: 1;
    color: #fff;
    padding: 90px 30px;
    font-size: 1rem;
    transform: translate3d(100%,0,0);
    transition: transform .2s cubic-bezier(.25,.1,.25,1);
    overflow: hidden;
    overflow-y: auto;
    max-width: 375px;
    transform: translateX(100%);
    min-height: 100vh;

    @include below($s) {
    	max-width: 100%;
    }

    &.is-open {
    	transform: translateX(0%);
    }

	&.child-open{

		.parent > a{
			opacity: 0;
			pointer-events: none;
		}
	}

	.back img{
		position: relative;;
		top: 1px;
		display: inline-block;
		height: 16px;
		margin-right: 30px;
	}

    ul {

	    list-style-type: none;
	    margin: 0;
	    outline: none;

	    li:first-child {
	    	border-top: 1px solid rgba(255,255,255,.7);
	    }
    }

    a {
		display: block;
		font-size: 20px;
		text-decoration: none;
	    color: #fff;
	    border-bottom: 1px solid rgba(255,255,255,.7);
	    transition: all .2s ease-out;
	    position: relative;
	    padding: 20px 0;
    }

    .has-children {

    	> a:after {
		    content: '';
		    display: block;
		    position: absolute;
		    top: 50%;
		    transform: translateY(-50%);
			background-image: url(../img/icon-arrow.png);
			background-size: auto 100%;
			background-repeat: no-repeat;
			width: 8px;
			height: 13px;
		    right: 0;
    	}
    }

    .sub-menu {
    	display: block;
	    position: absolute;
	    left: 0;
	    top: 0;
	    z-index: -1;
	    height: 100%;
	    width: 100%;
	    padding: 5.625rem 1.875rem 1.125rem;
	    transition: all .3s ease-out;
    	transform: translateX(100%);

	    &.is-open {
	    	transform: translateX(0%);
	    	display: block;
	    	z-index: 2;
	    }
    }

    .icon-close{
		position: absolute;
		top: 40px;
		right: 40px;
		width: 28px;
		border: none;
		padding: 5px;
	    z-index: 10;
	    cursor: pointer;

		img{
			width: 100%;
		}

		@include below($m) {
			top: 20px;
			right: 10px;
		}

		@include below($s) {
			top: 10px;
			right: 10px;
		}
	}
}
