$gutter: 4px;

// Grid settings.
@mixin grid($size, $gutter: $gutter) {
	$cycle: (12 / $size);
	$colSize: #{$size + '/12'};
	width: calc(100% *  #{$colSize} - (#{$gutter} - #{$gutter} * #{$colSize}));

 	&:nth-child(n) {
   		margin-right: $gutter;
  	}
  	&:last-child {
    	margin-right: 0;
  	}
  	&:nth-child(#{$cycle}n) {
    	margin-right: 0 !important;
  	}
}

// Custom settings.
.align-center{
	text-align: center;
}

.col-right {
  float: right !important;
  box-sizing: border-box;
}

.col-left {
  float: right !important;
  box-sizing: border-box;
}

.col-center{
	display: block;
	float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

@include above($l){
    .col-1{
    	@include grid(1);

    	@include below($m){
    		@include grid(1, $gutter / 2);
    	}
    }
    .col-2{
    	@include grid(2);

    	@include below($m){
    		@include grid(2, $gutter / 2);
    	}
    }
    .col-3{
    	@include grid(3);

    	@include below($m){
    		@include grid(3, $gutter / 2);
    	}
    }
    .col-4{
    	@include grid(4);

    	@include below($m){
    		@include grid(4, $gutter / 2);
    	}
    }
    .col-5{
    	@include grid(5);

    	@include below($m){
    		@include grid(5, $gutter / 2);
    	}
    }
    .col-6{
    	@include grid(6);

    	@include below($m){
    		@include grid(6, $gutter / 2);
    	}
    }
    .col-7{
    	@include grid(7);

    	@include below($m){
    		@include grid(7, $gutter / 2);
    	}
    }
    .col-8{
    	@include grid(8);

    	@include below($m){
    		@include grid(8, $gutter / 2);
    	}
    }
    .col-9{
    	@include grid(9);

    	@include below($m){
    		@include grid(9, $gutter / 2);
    	}
    }
    .col-10{
    	@include grid(10);

    	@include below($m){
    		@include grid(10, $gutter / 2);
    	}
    }
    .col-11{
    	@include grid(11);

    	@include below($m){
    		@include grid(11, $gutter / 2);
    	}
    }
    .col-12{
    	@include grid(12);

    	@include below($m){
    		@include grid(12, $gutter / 2);
    	}
    }
}

@include below($l){
    .l-col-1 {
		@include grid(1);
		&.no--gutter{
			@include grid(1, $gutter:0px);
		}
	}
	.l-col-2 {
		@include grid(2);
		&.no--gutter{
			@include grid(2, $gutter:0px);
		}
	}
	.l-col-3 {
		@include grid(3);
		&.no--gutter{
			@include grid(3, $gutter:0px);
		}
	}
	.l-col-4 {
		@include grid(4);
		&.no--gutter{
			@include grid(4, $gutter:0px);
		}
	}
	.l-col-5 {
		@include grid(5);
		&.no--gutter{
			@include grid(5, $gutter:0px);
		}
	}
	.l-col-6 {
		@include grid(6);
		&.no--gutter{
			@include grid(6, $gutter:0px);
		}
	}
	.l-col-7 {
		@include grid(7);
		&.no--gutter{
			@include grid(7, $gutter:0px);
		}
	}
	.l-col-8 {
		@include grid(8);
		&.no--gutter{
			@include grid(8, $gutter:0px);
		}
	}
	.l-col-9 {
		@include grid(9);
		&.no--gutter{
			@include grid(9, $gutter:0px);
		}
	}
	.l-col-10 {
		@include grid(10);
		&.no--gutter{
			@include grid(10, $gutter:0px);
		}
	}
	.l-col-11 {
		@include grid(11);
		&.no--gutter{
			@include grid(11, $gutter:0px);
		}
	}
	.l-col-12 {
		@include grid(12);
		&.no--gutter{
			@include grid(12, $gutter:0px);
		}
	}
}

@include below($m){
    .m-col-1 {
        @include grid(1);
        &.no--gutter{
            @include grid(1, $gutter:0px);
        }
    }
    .m-col-2 {
        @include grid(2);
        &.no--gutter{
            @include grid(2, $gutter:0px);
        }
    }
    .m-col-3 {
        @include grid(3);
        &.no--gutter{
            @include grid(3, $gutter:0px);
        }
    }
    .m-col-4 {
        @include grid(4);
        &.no--gutter{
            @include grid(4, $gutter:0px);
        }
    }
    .m-col-5 {
        @include grid(5);
        &.no--gutter{
            @include grid(5, $gutter:0px);
        }
    }
    .m-col-6 {
        @include grid(6);
        &.no--gutter{
            @include grid(6, $gutter:0px);
        }
    }
    .m-col-7 {
        @include grid(7);
        &.no--gutter{
            @include grid(7, $gutter:0px);
        }
    }
    .m-col-8 {
        @include grid(8);
        &.no--gutter{
            @include grid(8, $gutter:0px);
        }
    }
    .m-col-9 {
        @include grid(9);
        &.no--gutter{
            @include grid(9, $gutter:0px);
        }
    }
    .m-col-10 {
        @include grid(10);
        &.no--gutter{
            @include grid(10, $gutter:0px);
        }
    }
    .m-col-11 {
        @include grid(11);
        &.no--gutter{
            @include grid(11, $gutter:0px);
        }
    }
    .m-col-12 {
        @include grid(12);
        &.no--gutter{
            @include grid(12, $gutter:0px);
        }
    }
}

@include below($s){
	.col-1,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-10,
	.col-11,
	.col-12{
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

.s-col-1 {
		@include grid(1);
		&.no--gutter{
			@include grid(1, $gutter:0px);
		}
	}
	.s-col-2 {
		@include grid(2);
		&.no--gutter{
			@include grid(2, $gutter:0px);
		}
	}
	.s-col-3 {
		@include grid(3);
		&.no--gutter{
			@include grid(3, $gutter:0px);
		}
	}
	.s-col-4 {
		@include grid(4);
		&.no--gutter{
			@include grid(4, $gutter:0px);
		}
	}
	.s-col-5 {
		@include grid(5);
		&.no--gutter{
			@include grid(5, $gutter:0px);
		}
	}
	.s-col-6 {
		@include grid(6);
		&.no--gutter{
			@include grid(6, $gutter:0px);
		}
	}
	.s-col-7 {
		@include grid(7);
		&.no--gutter{
			@include grid(7, $gutter:0px);
		}
	}
	.s-col-8 {
		@include grid(8);
		&.no--gutter{
			@include grid(8, $gutter:0px);
		}
	}
	.s-col-9 {
		@include grid(9);
		&.no--gutter{
			@include grid(9, $gutter:0px);
		}
	}
	.s-col-10 {
		@include grid(10);
		&.no--gutter{
			@include grid(10, $gutter:0px);
		}
	}
	.s-col-11 {
		@include grid(11);
		&.no--gutter{
			@include grid(11, $gutter:0px);
		}
	}
	.s-col-12 {
		@include grid(12);
		&.no--gutter{
			@include grid(12, $gutter:0px);
		}
	}
}

[class*="col-"]{
	float: left;
	&:last-child{
		margin-right: 0;
	}
}
