.fact-box-holder {
	position: relative;
	overflow: hidden;
	margin-top: 40px;
	padding: 35px 25px;
	background: $lightGray;

	h2.title-m {
		color: $gray;
		font-weight: bold;
	}

	.row {
		// border-top: 1px solid #979797;
		// display: flex;

		@include below($s) {
			display: block;
			width: 100%;
			float: left;
		}

		&:first-of-type,
		&:last-of-type {
			border: none;
		}

		.half:nth-child(2) {
			padding-right: 0px;
			padding-left: 20px;
			border-left: 1px solid #979797;
		}
	}

	.fact-box {
		vertical-align: top;
		display: inline-block;
		width: 100%;
		float: left;
		padding: 0;
		margin: 25px 0;

		&.half {
			width: 50%;
			padding: 0 20px 0 0;

			@include below($s) {
				width: 100%;
				padding: 0 0 10px 0 !important;
				border: none !important;
			}
		}

		h2.title-xs {
			margin-bottom: 15px;
			font-weight: bold;
		}

		p.list-items {
			font-size: 16px;
			line-height: 18px;
			margin: 8px 0;
		}

		p {
			margin: 0;
		}

		p.note{
			color: #999;
			font-size: 14px;
			line-height: 20px;
			margin: 10px 0;
		}

		img {
			width: 100%;
			height: auto;
		}

		ul {
			list-style: none;

			li {
				margin-bottom: 8px;
			}
		}
	}


	// TODO
	table{
		display: block;
		font-size: 16px;
		width: 100%;
		background: #fff;
		position: relative;

		tr{
			width: 100%;
		}

		th{
			background: rgba(255,255,255,1);
		}
		tr:nth-child(even){
			background: $lightGray;

		}

		th,
		td{
			width: 400px;
			text-align: right;
			padding: 15px 15px;
			position: relative;

			span{
				position: absolute;
    			left: 94%;
    			text-align: left;
			}

			&:first-child {
				text-align: left;
			}
		}

		@include below($m){
			overflow: hidden;

			&:after{
				pointer-events: none;
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: calc(100% - 25px);
				height: 100%;
				width: 25px;
				background: linear-gradient(to right, rgba(0,0,0,0) , rgba(0,0,0,.08));
			}

			tbody{
				display: block;
			    font-size: 16px;
			    width: 100%;
			    //min-width: 460px;
			    background: #fff;
			    position: relative;
				overflow-x: scroll;
			}

			th,
			td{
				min-width: 100px;
			}

			td:first-child,
			th:first-child {
				min-width: 200px;
			}

			th:last-child,
			td:last-child{
				padding-right: 35px;
			}

		}
	}

}
