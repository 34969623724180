.article-grid {
	padding: 80px 0;
	@include horizontal-align();
}

.article-grid article{
	float: left;
	height: 260px * 1;
	width: calc(((100% / 12) * 4) - 8px);
	margin: 4px;
	position: relative;
	background: #fff;
	opacity: 1;
	transition: .2s;

	&:hover{
		background: rgba(0,0,0, .1);
		opacity: .9;

		.icon-right-open {
			transform: translateX(5px);
		}
	}

	.image-holder {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-size: cover !important;
		background-position: center !important;
		background-blend-mode: multiply;
	}

	.text-holder {
		padding: 25px;
		z-index: 1;
		text-align: center;
		height: auto;
		@include vertical-align();

		@include below($s) {
			position: absolute;
		}

		.label {
			margin-bottom: 7px;
			display: block;
		    font-size: 20px;
		    line-height: 24px;

			@include below($m){
				font-size: 16px;
				line-height: 20px;
			}
		}

		p {
    		margin: 5px 0;
		}
	}

	.icon {
	    width: 55px;
	    height: 55px;
	    background-size: cover;
	    @include horizontal-align();
	    margin-bottom: 15px;
	}

	.icon-right-open {
		display: none;
		transition: .2s transform;
		transform: translateX(0);
		right: 25px;
		bottom: 26px;
		z-index: 1;
		position: absolute;
		font-size: 17px;
	}

	&.video {

		.icon-right-open {
			display: none;
		}

		.image-holder:after {
			content: '';
			display: block;
			z-index: 1;
			background-image: url('../img/icon-video.png');
			background-size: cover;
			width: 42px;
			height: 42px;
			@include align-both(relative);

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&.wide{
		width: calc(((100% / 12) * 8) - 8px);

		.image-holder {
			width: calc( 50% - 2px );
			height: 100%;
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		.text-holder {
			width: calc( 50% - 2px );
			marign-left: 2px;
			display: inline-block;

			@include below($s) {
				top: 0;
				transform: none;
				position: relative;
			}
		}

		.image-holder.right {
			width: calc( 50% - 1px );
		}
	}

	&.high{
		height: 264px * 2;
		float: right;

		.image-holder, .text-holder {
			height: 260px;
			width: 100%;
			position: relative;
			display: inline-block;
			top: 0;
			transform: none;

			@include below($s) {

				.text-holder {
					height: auto;
					position: relative;
				}
			}
		}

		.inner {
			@include vertical-align();

			@include below($s) {
				top: 0;
				transform: none;
			}
		}
	}

	&.yellow {

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(to bottom, transparent, #FBE300);
		}
	}

	&.gradient .text-holder {
		@include vertical-align();
	}

	&.overlay-white:after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(255,255,255,.7);
	}
	&.overlay-black:after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0,0,0,.4);
	}

	&.white {

		.text-holder, .icon-right-open:before {
			color: #ffffff;
		}
	}

	&.right {

		.icon-right-open:before {
			color: #ffffff;

			@include below($s) {
				color: #000;
			}
		}
	}

	&.beige {
		background: linear-gradient(to bottom, #D1CCC7, #A59D95);

		.text-holder {
			@include vertical-align();
		}
	}

	@include below($s) {
		margin: 10px 0;
		width: 100% !important;
		height: auto !important;
		min-height: 260px;

		.text-holder {
			width: 100% !important;
			display: block;
			height: auto !important;
		}

		.image-holder {
			width: 100% !important;
			display: block;
			height: 260px !important;
		}

		&.yellow {

			&:after {
				display: none;
			}
		}

		&.beige .text-holder {
			position: absolute;
		}

		&.gradient .text-holder {
			@include vertical-align(absolute);
		}
	}
}