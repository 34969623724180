body{
	font-family: Georgia;
}

.left{}
.right{
	float: right;
}

.container{
	margin: 0 auto;
	max-width: 870px;
	padding: 0 40px;
}