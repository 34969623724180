footer {
	background: $lightGray;
	position: relative;

	.footer-logo {
		background-image: url('../img/footer_logo.png');
		width: 24px;
		height: 22px;
		background-size: cover;
		position: absolute;
		top: 55px;
		left: 35px;

		@include below($m) {
			top: 35px;
		}
	}

	.container {
		padding: 0;
	}

	ul {
		list-style: none;
		font-size: 0;
		padding: 45px 0;

		li {
			color: $black;
			text-transform: uppercase;
			font-size: 13px;
			display: inline-block;
			width: 25%;
			padding: 15px 0;
			letter-spacing: 1px;

			a {
				text-decoration: none;
			}
		}

		@include below($m) {
			padding: 80px 35px 35px;

			li {
				width: 100%;
				display: block;
				padding: 8px 0;
			}
		}
	}
}