.list-item-title{
	margin-bottom: 30px;
}
.list-item {
	position: relative;
	background: $lightGray;
	padding: 20px 40px 0 210px;
	min-height: 180px;
	margin: 8px 0;
	display: block;
	text-decoration: none;
	box-shadow: 0 19px 38px 0 rgba(0,0,0,.0);
	transition: box-shadow .2s cubic-bezier(.25,.1,.25,1);

	&:hover{
		box-shadow: 0 5px 15px 0 rgba(0,0,0,.2);
	}

	&.no-image{
		padding-left: 40px;
		padding-bottom: 20px;
		min-height: 0;

		&:before{
			content: '';
			left: 0;
			top: 0;
			height: 100%;
			width: 10px;
			position: absolute;
			background: linear-gradient(to bottom, #D35098, #727FB7);
		}
	}

	img{
		position: absolute;
		top: 0;
		left: 0;
		height: 100% !important;
		width: auto;

	}

	p{
		margin: 10px 0;
		font-size: 16px;
		line-height: 23px;
	}

	@include below($m){
		padding: 20px 40px 10px 180px;
		min-height: auto;

		&.no-image{
			padding-left: 30px;
			padding-bottom: 10px;
		}

		img{
			height: 150px !important;
		}

		p{
			font-size: 14px;
		}
	}

	@include below($s){

		position: relative;
		padding: 20px 40px 10px 20px;

		img{
			position: relative;
			margin-right: 20px;
			float: left;
			height: 100px !important;
		}

	}
}