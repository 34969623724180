// Buttons
.button{
	display: inline-block;
	border: 1px solid #fff;
	font-size: 25px;
	padding: 12px 40px 14px;
	color: #fff;
	text-decoration: none;
	border-radius: 50px;
	transition: .2s;
}

.home .hero .button:hover {
	background-color: rgba(254,254,254,.2)
}

.arrow{}