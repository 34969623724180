@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';
@import 'grid';


// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 900px;
$m: 1024px;
$l: 1250px;
$xl: 1410px;


// Default content settings.
$contentWidth: 1030px;
$contentPadding: 15px;
$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$black: #4A4A4A;
$gray: #9B9B9B;
$lightGray: #F3F3F6;
$pink: #D35098;

@import 'basics';
@import 'buttons';
@import 'types';
@import 'navigation';
@import 'footer';
@import 'fact-box';
@import 'article-grid';
@import 'list-item';

body {
	position: relative;
	color: $black;
	padding-top: 190px;

	&.home{
		padding-top: 0;
	}

	@include below($m) {
		padding-top: 60px;
	}

	@include below($s) {
		padding-top: 50px;
	}

}

@include below($s) {

	.hide-s{
		display: none !important;
	}
}

.wrapper {
	min-height: calc( 100vh - 180px );
}

main{
	padding-bottom: 70px;
}

// Parts
.hero{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 615px;
	position: relative;
	max-width: 1440px;
	margin: 0 auto;
	display: block;


	&-front{
		min-height: 800px;
		margin-top: 0;
		max-width: 100%;

		@include below($m) {
		    height: auto;
		    min-height: 100vh;
		    background-position: 30%;

		    &:after {
		    	content: '';
		    	position: absolute;
		    	top: 0;
		    	right: 0;
		    	bottom: 0;
		    	left: 0;
		    	background: rgba(0,0,0,.3);
		    }
		}
	}

	article{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
		margin: 0 auto;
		max-width: 95%;
		// width: 650px;
		text-align: center;
		z-index: 1;
		width: 750px;

		.title-xl {
			font-size: 70px;
		}

		@include below($m) {
			width: 450px;
		}

		@include below($s) {
			width: 300px;
		}

	}

	.button{
		margin-top:  80px;

		@include below($s) {
			margin-top: 50px;
		}
	}

	.hero-caption{
		position: absolute;
		bottom: 0;
		color: #fff;
		width: 100%;
		background: rgba(0,0,0, .5);

		h3{
			max-width: 790px;
			margin: 0 auto;
			padding: 20px 0;
		}

		@include below(1024px){
			h3{
				font-size: 14px;
			}

			padding-left: 40px;
			padding-right: 40px;
		}
	}

	@include below($s){
		height: 220px;
	}
}

body.en.home .hero {
	// background-image: url('../img/background-4_en.jpg') !important;
}

body.en.home {

	.hero article {
		width: 820px;

		@include below($m) {
			width: 520px;
		}

		@include below($s) {
			width: 340px;
		}
	}
}



.card {}

.side-decoration {
	position: absolute;
	width: 40px;
	top: 190px;
	bottom: 160px;
	background: linear-gradient(to bottom, #D35098, #00B5E5);
	opacity: .8;
	background-blend-mode: multiply;

	@include below($m) {
		width: 20px;
		top: 50px;
	}

	@include below($s) {
		width: 10px;
	}
}

.home .side-decoration {
	display: none;
}

.post {
	padding-top: 100px;
	padding-bottom: 100px;
	position: relative;

	&.no-image{
		padding-top: 0;

		@include below($m){
			padding-top: 60px !important;
		}

		@include below($s){
			padding-top: 30px !important;
		}
	}

	img {
		max-width: 100%;
		height: auto;
	}

	@include below($s){
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.video {
		position: relative;
		padding-bottom: 56.2%;
		width: 100%;
		height: 0;
		background-color: #000;
		margin: 20px 0;

		&.vimeo {
			padding-bottom: 56.3%;
		}

		&.fb {
			padding-bottom: 52.02%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.localvideo {
		width: 100%;
	}
}

.the-content {
	position: relative;

	.aligncenter{
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
	.alignleft{
		float: left;
		margin-right: 40px;
	}
	.alignright{
		float: right;
		margin-left: 40px;
	}
}

.author {
	position: absolute;
	width: 150px;
	height: auto;
	left: -180px;
	top: 7px;
	text-align: center;

	.image {
		width: 100%;
		height: 150px;
		background-size: cover;
	}

	p {
		font-size: 14px !important;
		line-height: 20px !important;
		font-style: italic;
		margin: 10px 0 20px;
	}

	@include below(1250px) {
		position: relative;
	    left: 0;
	    right: 0;
	    float: left;
	    margin: 0 30px 0 0;
	}
}

.post blockquote .author {

	@include below(1250px) {
	    display: block;
	    float: none;
	    margin: 20px 0;
	}
}

.sub-article{
	position: relative;;
	margin: 40px 0;
	padding: 40px 0;
	border-top: 1px solid #d9d9d9;

	&:first-of-type{
		border-top: none;
	}

	.sub-article-link {
		position: absolute;
		top: 3px;
	}

	.sub-article-title {
		text-decoration: none;
	}

	@include below(1250px) {
		min-height: 230px;

		.author{
			top: 0;
			position: relative;
		    left: 0;
		    float: left;
		    margin: 0 30px 0 0;
		}
	}
}

.related-container{
	margin-top: 30px;

	@include below($s){
		padding-left: 0px;
		padding-right: 0px;
	}
}

@import 'print';

