// Types
.title-xl{
	font-size: 90px;

	@include below($m){
		font-size: 60px !important;
	}

	@include below($s){
		font-size: 40px !important;
	}
}
.title-l{
	font-size: 36px;
	line-height: 40px;

	@include below($s){
		font-size: 28px;
	}
}
.title-m{
	font-size: 30px;

	@include below($s){
		font-size: 20px;
	}
}
.title-sm{
	font-size: 28px;
	line-height: 35px;

	@include below($m){
		font-size: 20px;
		line-height: 25px;
	}
}
.title-s{
	font-size: 24px;

	@include below($s){
		font-size: 18px;
	}
}

.title-xs{
	font-size: 20px;

	@include below($s){
		font-size: 18px;
	}
}

.preamble{
	margin: 36px 0 47px;
	font-size: 28px;
	line-height: 34px;
	color: #9B9B9B;

	p{
		font-size: inherit;
		line-height: inherit;
		color: inherit;
	}

	@include below(1024px){
		margin: 20px 0 30px;
		font-size: 20px;
		line-height: 26px;
	}
}

blockquote{
	position: relative;
	padding: 0;
	margin: 40px 0;

	&:before{
		content: '';
		position: absolute;
		left: -15px;
		top: 0;
		height: 100%;

		border-left: 3px solid $pink;
	}

	@include below($m) {

		&:before {
			left: -10px;
		}
	}

	@include below($s){
		margin: 30px 0;
	}
}


p {
	font-size: 20px;
	line-height: 30px;
	margin: 20px 0;


	@include below($s){
		font-size: 16px;
		line-height: 24px;
		margin: 20px 0;
	}
}

.wp-caption-text {
	text-align: center;
    font-size: 14px;
    font-style: italic;
    margin: 10px 0;
}

a {
	color: $black;
}

.pre-stroke:before{
	content: '';
	width: 65px;
	background: $black;
	height: 2px;
	display: inline-block;
	position: relative;
	top: -8px;
	margin-right: 30px;


	@include below($s){
		top: -6px;
		width: 20px;
		margin-right: 20px;
	}
}