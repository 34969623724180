@media print {

	body{
		padding-top: 30px;
	}

	.hero{
		-webkit-print-color-adjust: exact;
		height: 300px;
	}

	.author {
		position: relative;
	    left: 0;
	    margin-top: 40px;
	    float: right;
	    margin: 0 0 0 30px;
		width: 100px;

		.image {
		    height: 100px;
			-webkit-print-color-adjust: exact;
		}
	}

	.post{
		padding-top: 50px;
	}

	.home .navigation{
		display: block;
	}

	.navigation {
		display: none;

		.left{
			display: none;
		}
		.right{
			display: none;
		}
	}


	.article-grid{
		display: none;
	}

	//
	footer{
		display: none;
	}


	// Types
	.title-l{
		font-size: 28px;
    	line-height: 36px;
	}
	.title-m{}
	.preamble{
		font-size: 16px;
    	line-height: 24px;
    	margin: 20px 0;
	}

	p,
	.fact-box-holder .fact-box p.list-items{
		font-size: 14px;
    	line-height: 20px;
	}

	body .post.no-image {
    	padding-top: 0px !important;
	}

	video, audio, object,embed{
		display: none;
	}

	.related-container{
		display: none !important;
	}

	@page {
		margin: 2cm 0.5cm;
	}
}